<template>
  <v-container fluid>
    <v-app-bar app>
      <NavMenu v-if="$vuetify.breakpoint.mobile && !isCampaign" />
      <!--      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>-->
      <!--      <v-img-->
      <!--        v-if="$vuetify.breakpoint.lgAndUp"-->
      <!--        class="clickable mt-10"-->
      <!--        height="100"-->
      <!--        width="100"-->
      <!--        alt="Kondo CPA logo"-->
      <!--        src="@/assets/logoOnly.svg"-->
      <!--        :lazy-src="logoImg.lazylogo"-->
      <!--        @click="$router.push('/')"-->
      <!--        transition="scale-transition"-->
      <!--      >-->
      <!--      </v-img>-->
      <v-img
        class="clickable pa-0 ma-0"
        contain
        :class="logoImg.class"
        :height="logoImg.height"
        :width="logoImg.width"
        alt="Kondo CPA logo"
        :src="logoImg.logo"
        :lazy-src="logoImg.lazylogo"
        transition="scale-transition"
      />
      <!--      <v-spacer></v-spacer>-->
      <v-breadcrumbs
        large
        v-if="!$vuetify.breakpoint.mobile && !isCampaign"
        :items="breadcrumbs"
        divider="|"
      ></v-breadcrumbs>
      <div class="d-flex align-center" v-if="!isCampaign">
        <span class="primary--text mr-2 d-none d-md-flex">{{ btnText }}</span>
        <v-btn icon color="primary" href="tel:+972792016611">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
        <v-btn href="https://wa.me/972792016611" icon color="primary">
          <v-icon class="ma-2">mdi-whatsapp</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
  </v-container>
</template>

<script>
import NavMenu from "@/components/NavMenu";

export default {
  name: "Header",
  components: {
    NavMenu
  },
  data: () => ({
    title: "קונדו ושות' רואי חשבון",
    btnText: "צרו קשר",
    breadcrumbs: [
      { text: "דף הבית", href: "/" },
      { text: "פתיחת תיק עוסק בחינם!", href: "/new" },
      // { text: "שירותים" },
      { text: "האפליקציה שלנו", href: "/app" },
      { text: "כניסה ללקוחות", href: "https://kbn.finbot.co.il/hs-user" },
      { text: "מי אנחנו", href: "/about" }
    ]
  }),
  computed: {
    logoImg() {
      // let path = '../assets/logoTextOnly.svg'
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            logo: require("@/assets/kondosvg 10.svg"),
            lazylogo: require("@/assets/kondosvg-10.gif"),
            height: "150",
            width: "150",
            class: "mt-2"
          };
        case "sm":
          return {
            logo: require("@/assets/kondosvg 10.svg"),
            lazylogo: require("@/assets/kondosvg-10.gif"),
            height: "130",
            width: "130",
            class: "mt-2"
          };
        case "md":
          return {
            logo: require("@/assets/kondosvg 10.svg"),
            lazylogo: require("@/assets/kondosvg-10.gif"),
            height: "150",
            width: "150",
            class: "mt-2"
          };
        case "lg":
          return {
            logo: require("@/assets/kondosvg 10.svg"),
            lazylogo: require("@/assets/kondosvg-10.gif"),
            height: "150",
            width: "150",
            class: "mt-1"
          };
        default:
          return {
            logo: require("@/assets/kondosvg 10.svg"),
            lazylogo: require("@/assets/kondosvg-10.gif"),
            height: "130",
            width: "130",
            class: ""
          };
      }
    },
    isCampaign() {
      return (
        this.$route.path === "/campaign" && this.$route.query.campaign === "zap"
      );
    }
  }
};
</script>

<style scoped></style>
