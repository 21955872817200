import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/Main";
import Campaign from "@/views/Campaign";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "view" */ "../views/Home")
  },
  {
    path: "/app",
    name: "DownloadApp",
    component: () =>
      import(/* webpackChunkName: "download-app" */ "../views/DownloadApp")
  },
  {
    path: "/new",
    name: "OpenNewBiz",
    component: () =>
      import(/* webpackChunkName: "open-new-biz" */ "../views/OpenNewBiz")
  },
  {
    path: "/campaign",
    name: "Campaign",
    component: Campaign
    // component: () =>
    //   import(/* webpackChunkName: "open-new-biz" */ "../views/Campaign")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
