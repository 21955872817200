<template>
  <v-container>
    <!--    <div>Icons made by <a href="" title="itim2101">itim2101</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>-->
    <!--    <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>-->
    <v-row>
      <v-col align="end">
        <div class="credits">
          Icons made by:
          <a
            href="https://www.flaticon.com/authors/pixel-perfect"
            title="Pixel perfect"
          >
            Pixel perfect
          </a>
          <a href="https://www.flaticon.com/authors/xnimrodx" title="xnimrodx">
            | xnimrodx
          </a>
          <a href="https://www.freepik.com" title="Freepik"> | Freepik </a>
          <a href="https://www.flaticon.com/authors/catkuro" title="catkuro">
            | catkuro
          </a>
          <a href="" title="itim2101"> | itim2101</a>
          from
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Credits"
};
</script>

<style scoped lang="scss">
.credits {
  font-size: 10px;
  a {
    color: #2c3e50;
    text-decoration: none;
  }
}
</style>
