<template>
  <v-card-actions>
    <!-- move to action component -->
    <v-btn color="primary" text @click="clearFormData">
      נקה שדות
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      v-if="stepper.currStep > 1"
      color="primary"
      text
      @click="stepper.currStep--"
    >
      <v-icon>mdi-chevron-right</v-icon>
      <span>חזור</span>
    </v-btn>
    <v-btn
      v-if="stepper.currStep < stepper.steps.length"
      color="primary"
      text
      @click="next()"
    >
      <span>הבא</span>
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-if="stepper.currStep === 3"
      color="primary"
      @click="submitForm(form.fields)"
    >
      <v-icon>mdi-send</v-icon>
      <span>שלח</span>
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "FormActions",
  props: ["stepper", "clearFormData", "next", "form", "submitForm"]
};
</script>

<style scoped></style>
