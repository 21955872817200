<template>
  <v-container fluid>
    <v-card-title>
      <v-row>
        <div class="text-h5 mr-3">{{ form.title }}</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <div class="pt-4 text-subtitle-1">{{ form.subtitle }}</div>
    </v-card-subtitle>
  </v-container>
</template>

<script>
export default {
  name: "FormTitle",
  props: ["form", "closeForm"]
};
</script>

<style scoped></style>
