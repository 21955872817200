<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-large v-bind="attrs" v-on="on" icon text>
        <v-icon color="primary">
          mdi-menu
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :href="item.href"
        target="_blank"
        @click="$router.push(item.route)"
      >
        <v-list-item-title v-bind:class="['clickable', item.class]">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "NavMenu",
  data: () => ({
    items: [
      { title: "Home", text: "לדף הבית", route: "/" },
      { title: "About", text: "מי אנחנו", route: "/about" },
      { title: "DownloadApp", text: "לאפליקציה", route: "/app" },
      {
        title: "OpenNewBiz",
        text: "פתיחת עוסק בחינם!",
        route: "/new",
        class: "font-weight-bold"
      },
      {
        title: "LoginUsers",
        text: "כניסה ללקוחות",
        href: "https://kbn.finbot.co.il/hs-user"
      }
    ]
  })
};
</script>
