<template>
  <v-stepper v-model="stepper.currStep" vertical>
    <div v-for="step in stepper.steps" :key="step.id">
      <v-stepper-step
        :step="step.id + 1"
        :complete="stepper.currStep > step.id + 1"
        @click="stepClicked(step)"
        style="cursor: pointer"
      >
        <div>{{ step.text }}</div>
      </v-stepper-step>
      <v-stepper-content :step="step.id + 1" class="mx-auto">
        <FormFields
          :step="step"
          :getRules="getRules"
          :isLoading="isLoading"
          :uploadFile="uploadFile"
        />
      </v-stepper-content>
    </div>
  </v-stepper>
</template>

<script>
import FormFields from "./FormFields";

export default {
  name: "FromStepper",
  props: ["stepper", "stepClicked", "getRules", "isLoading", "uploadFile"],
  components: { FormFields }
};
</script>

<style scoped></style>
