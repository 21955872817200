<template>
  <v-card-text>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="field in step.data"
          :key="field.id"
          cols="auto"
          :sm="field.sizeBreakPoints.sm"
          :md="field.sizeBreakPoints.md"
        >
          <v-text-field
            v-if="field.type.fieldType === 'text'"
            v-model="field.value"
            :label="field.label"
            :type="field.type.inputType"
            :hint="field.hint"
            :required="field.isRequired"
            :rules="getRules(field.rules)"
          >
          </v-text-field>

          <v-select
            v-else-if="field.type.fieldType === 'multi'"
            v-model="field.value"
            :items="field.items"
            :label="field.label"
            :rules="getRules(field.rules)"
          >
          </v-select>

          <v-checkbox
            v-else-if="field.type.fieldType === 'checkbox'"
            v-model="field.value"
            :label="field.label"
            color="primary"
            :rules="getRules(field.rules)"
          >
          </v-checkbox>

          <v-file-input
            v-else-if="field.type.fieldType === 'upload'"
            :ref="field.type.fieldType + '-' + field.id"
            counter
            show-size
            :label="field.label"
            :loading="isLoading"
            @change="uploadFile($event, field)"
            capture="camera"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  name: "FormFields",
  props: ["step", "getRules", "isLoading", "uploadFile"]
};
</script>

<style scoped></style>
