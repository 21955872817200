import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

import colors from "vuetify/lib/util/colors";

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.teal.accent4,
        accent: colors.cyan,
        error: colors.red.accent3
      }
    }
  }
});
