<template>
  <v-container fluid>
    <v-dialog
      v-model="showForm"
      persistent
      max-width="600px"
      @keydown.esc="closeForm"
    >
      <v-overlay :value="isSendFormLoading" absolute>
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
          :width="10"
        >
        </v-progress-circular>
      </v-overlay>

      <slot></slot>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "FormDialogPopup",
  props: ["showForm", "closeForm", "isSendFormLoading"],
  data: () => ({})
};
</script>

<style scoped></style>
