<template>
  <v-app>
    <v-container id="app" class="pt-0 mt-0">
      <Header />
      <v-main>
        <router-view />
      </v-main>
      <v-footer padless>
        <v-col class="text-center" cols="12">
          <ContactUs />
          <Credits />
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import ContactUs from "@/components/ContactUs";
import Credits from "@/components/Credits";

export default {
  name: "App",
  metaInfo: {
    title: "Kondo CPA",
    // all titles will be injected into this template
    titleTemplate: "%s | Kondo & Co CPA "
  },
  components: { Header, ContactUs, Credits }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.clickable {
  cursor: pointer;
}
</style>
