<template>
  <v-dialog v-model="showThankYouPopup" persistent max-width="600px">
    <v-card rounded>
      <v-card-title>
        <v-img max-height="100" contain src="../assets/approved.png" />
        <div>
          <div class="mt-4">
            הפרטים נשלחו בהצלחה!
          </div>
          <div>
            תודה רבה!
          </div>
        </div>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ThankYouPopup",
  props: {
    showThankYouPopup: {}
  }
};
</script>
