<template>
  <v-container fluid>
    <v-row justify="center">
      <FormDialogPopup
        :showForm="showForm"
        :closeForm="closeForm"
        :isSendFormLoading="isUploadLoading && isSendFormLoading"
      >
        <v-card>
          <v-form ref="form">
            <FormTitle :form="form" :closeForm="closeForm" />

            <FromStepper
              :stepper="stepper"
              :stepClicked="goToStep"
              :getRules="getRules"
              :isLoading="isUploadLoading"
              :uploadFile="uploadFile"
            />

            <FormActions
              :stepper="stepper"
              :clearFormData="clearFormData"
              :next="next"
              :form="form"
              :submitForm="submitForm"
            >
            </FormActions>
          </v-form>
        </v-card>
      </FormDialogPopup>
    </v-row>
  </v-container>
</template>

<script>
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import FormDialogPopup from "./formComponents/FormDialogPopup";
import FormTitle from "./formComponents/FormTitle";
import FormActions from "./formComponents/FormActions";
import FromStepper from "./formComponents/FromStepper";
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool
} = require("@aws-sdk/credential-provider-cognito-identity");

export default {
  // TODO: move functions to vuex
  name: "FormDetails",
  props: ["showForm"],
  components: {
    FormDialogPopup,
    FormTitle,
    FormActions,
    FromStepper
  },
  data: () => ({
    response: "",
    isUploadLoading: false,
    isSendFormLoading: false,
    form: {
      title: "טופס פתיחת עסק",
      subtitle: "מלאו את הטופס, ואנו נפתח עבורכם תיק עוסק בחינם",
      valid: false,
      fields: [
        {
          id: 0,
          name: "firstName",
          value: "",
          label: "שם פרטי*",
          hint: "כפי שמופיע בתז",
          step: 1,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          isRequired: true
          // rules: ["required"]
        },
        {
          id: 1,
          name: "lastName",
          value: "",
          label: "שם משפחה*",
          hint: "כפי שמופיע בתז",
          step: 1,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          isRequired: true,
          rules: []
        },
        {
          id: 2,
          name: "businessName",
          value: "",
          label: "שם העסק (לא חובה)",
          hint: "כפי שמופיע בחשבוניות",
          step: 1,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: []
        },
        {
          id: 3,
          name: "phoneNumber",
          value: "",
          label: "מספר טלפון",
          hint: "",
          step: 1,
          type: {
            inputType: "tel",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: []
        },
        {
          id: 4,
          name: "email",
          value: "",
          label: 'דוא"ל',
          hint: "",
          step: 1,
          type: {
            inputType: "email",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: ["email"]
        },
        {
          id: 5,
          name: "idNumber",
          value: "",
          label: "מספר תז",
          hint: "",
          step: 2,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: ["required"]
        },
        {
          id: 6,
          name: "selectId",
          value: "",
          label: "בחר תיעוד נוסף",
          step: 2,
          items: ["מס' רשיון נהיגה", "מס' דרכון", "מס' זהות של אחד ההורים"],
          isHide: false,
          type: {
            inputType: "string",
            fieldType: "multi"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: ["required"]
        },
        {
          id: 7,
          name: "selectId-data",
          value: "",
          label: "הזן מספר",
          step: 2,
          isDisabled: true,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 4
          },
          rules: ["required"]
        },
        {
          id: 8,
          name: "uploadIdFile",
          value: [],
          label: "צילום תז",
          hint: "",
          step: 2,
          type: {
            inputType: "upload",
            fieldType: "upload"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 6
          },
          rules: []
        },
        {
          id: 9,
          name: "agreeToService",
          value: "",
          label:
            "אני מאשר בזאת לקונדו ושות' רואי חשבון לפתוח בהליך רישום ייצוגי אל מול רשות המיסים ופתיחת תיק",
          step: 2,
          type: {
            inputType: "boolean",
            fieldType: "checkbox"
          },
          sizeBreakPoints: {
            sm: 12,
            md: 12
          },
          rules: ["required"]
        },
        {
          id: 10,
          name: "industry",
          value: "",
          label: "תחום עיסוק",
          hint: "",
          step: 3,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 6
          },
          rules: []
        },
        {
          id: 11,
          name: "address",
          value: "",
          label: "כתובת העסק",
          hint: "",
          step: 3,
          type: {
            inputType: "string",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 6,
            md: 6
          },
          rules: []
        },
        {
          id: 12,
          name: "expectedIncome",
          value: "",
          label: "מחזור שנתי משוער",
          hint: "",
          step: 3,
          type: {
            inputType: "number",
            fieldType: "text"
          },
          sizeBreakPoints: {
            sm: 12,
            md: 6
          },
          rules: []
        },
        {
          id: 13,
          name: "willHireEmployees",
          value: "",
          label: "מתכוון להעסיק עובדים בשלב הראשון",
          step: 3,
          type: {
            inputType: "boolean",
            fieldType: "checkbox"
          },
          sizeBreakPoints: {
            sm: 12,
            md: 12
          }
        }
      ]
    },
    stepper: {
      currStep: 1,
      steps: [
        { id: 0, step: 1, text: "פרטים אישיים", data: [] },
        { id: 1, step: 2, text: "פרטי העסק", data: [] },
        { id: 2, step: 3, text: "פרטים עבור הרשויות", data: [] }
      ]
    }
  }),
  methods: {
    saveAndContinue() {
      localStorage.setItem("formData", JSON.stringify(this.form.fields));
    },
    closeForm(state) {
      this.saveAndContinue();
      this.$emit("close-form", state);
    },
    submitForm(data) {
      this.submit(data);
      this.closeForm("done");
    },
    clearFormData() {
      localStorage.removeItem("formData");
      this.form.fields.forEach(field => {
        if (field.type.fieldType === "upload") {
          field.value = [];
        } else {
          field.value = "";
        }
      });
      this.$refs.form.resetValidation();
    },
    next() {
      // TODO: Fix form validation and send
      this.saveAndContinue();

      const fieldsToSubmit = this.form.fields.filter(
        field => field.value.length > 0
      );
      this.submit(fieldsToSubmit);
      if (this.stepper.currStep !== this.stepper.steps.length) {
        this.stepper.currStep++;
      } else {
        this.stepper.currStep = 1;
      }
    },
    submit(data) {
      const enrichedData = this.addFieldsToEmail(data);

      const raw = this.parseData(enrichedData);
      const formData = data;
      let shouldSendData = false;

      for (const field of formData) {
        if (field.value) shouldSendData = true;
      }
      if (shouldSendData) {
        this.sendEmail(raw);
        this.saveTos3(raw);
      }
    },
    async uploadFile(clickEvent, field) {
      if (clickEvent) {
        this.isUploadLoading = true;
        let reader = new FileReader();
        const s3 = new S3Client({
          region: "us-east-1",
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({
              region: "us-east-1"
            }),
            identityPoolId: "us-east-1:fa12596e-92c1-4e11-ba83-8f9077bbda15"
          })
        });
        const currField = this.form.fields.filter(f => f.id === field.id)[0];
        reader.onload = async event => {
          const command = new PutObjectCommand({
            Bucket: "kondo-test-bucket",
            ContentType: clickEvent.type,
            Key: clickEvent.name,
            Body: event.target.result
          });
          try {
            const fileSent = await s3.send(command);
            if (fileSent) {
              currField.value = `https://kondo-test-bucket.s3.amazonaws.com   ${clickEvent.name}`;
              console.log("File uploaded successfully");
            }
          } catch (error) {
            console.log("Upload failed");
          } finally {
            this.isUploadLoading = false;
          }
        };

        await reader.readAsArrayBuffer(clickEvent);
      }
    },
    getRules(kinds) {
      let rules = [];
      if (kinds) {
        kinds.forEach(rule => {
          switch (rule) {
            case "required":
              rules.push(value => !!value || "נדרש");
              break;
            case "email":
              rules.push(value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'דוא"ל נדרש';
              });
          }
        });
      }
      return rules;
    },
    goToStep(step) {
      this.stepper.currStep = step.step;
      // This is not a good idea...
      if (this.stepper.currStep === 2 || this.stepper.currStep === 3) {
        this.saveAndContinue();
        const fieldsToSubmit = this.form.fields.filter(
          field => field.value.length > 0
        );
        this.submit(fieldsToSubmit);
      }
    },
    parseData(data) {
      let dataToSend = [];
      data.forEach(field =>
        dataToSend.push({
          id: field.id,
          name: field.name,
          value: field.value,
          type: field.type
        })
      );
      return JSON.stringify(dataToSend);
    },
    sendEmail(raw) {
      this.isSendFormLoading = true;
      // TODO: make async
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // if (!process.env.NODE_ENV === "development") {
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(
        "https://4zfhpadrl9.execute-api.us-east-1.amazonaws.com/default/sendEmail",
        requestOptions
      )
        .then(response => response.json())
        .then(result => (this.response = result))
        .catch(error => console.log("error", error))
        .finally(() => {
          this.isSendFormLoading = false;
        });
      // }
    },
    async saveTos3(data) {
      this.isSendFormLoading = true;
      const file = new File([data], `${Date.now()}.txt`, {
        type: "application/json"
      });
      const s3 = new S3Client({
        region: "us-east-1",
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({
            region: "us-east-1"
          }),
          identityPoolId: "us-east-1:fa12596e-92c1-4e11-ba83-8f9077bbda15"
        })
      });

      let reader = new FileReader();
      reader.onload = async event => {
        const command = new PutObjectCommand({
          Bucket: "kondo-test-bucket-data",
          ContentType: "string",
          Key: `${Date.now()}`,
          Body: event.target.result
        });
        try {
          const fileSent = await s3.send(command);
          console.log(`${fileSent} uploaded`);
        } catch (error) {
          console.log("s3 Upload failed");
        }
      };

      await reader.readAsArrayBuffer(file);
      this.isSendFormLoading = false;
    },
    addFieldsToEmail(data) {
      let res = [...data];
      if (
        this.$route.path === "/campaign" &&
        !data.some(field => field.id === 99)
      ) {
        const campaignVendor = this.$route.query.campaign || "no campaign";
        res.push({
          id: 99,
          name: "campaign",
          value: campaignVendor
        });
      }
      return res;
    }
  },
  computed: {},
  created() {
    const formData = localStorage.getItem("formData");
    if (formData) this.form.fields = JSON.parse(formData);
    this.form.fields.forEach(field => {
      this.stepper.steps.forEach(step => {
        if (field.step === step.step && !field.isHide) {
          step.data.push(field);
        }
      });
    });
  }
};
</script>
<style scoped></style>
