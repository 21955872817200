<template>
  <v-container fluid>
    <v-row align="center" justify="space-around">
      <h1>
        <span class="primary--text" @click="openWApp">
          צרו קשר
        </span>
      </h1>
      <!--      <v-btn x-large text color="primary" href="https://wa.me/972792016611">-->
      <!--        צרו קשר-->
      <!--      </v-btn>-->
    </v-row>
    <v-progress-circular
      v-if="isLoading"
      class="mt-10"
      :size="50"
      indeterminate
      color="primary"
    />
    <transition name="slide-fade">
      <v-form v-if="!isLoading" v-model="valid">
        <v-row justify="center">
          <v-col cols="12" sm="6" lg="4">
            <v-text-field
              v-model="firstName"
              :rules="nameRules"
              :counter="10"
              label="שם"
              required
            ></v-text-field>
          </v-col>

          <!--        <v-col cols="4" md="4">-->
          <!--          <v-text-field-->
          <!--            v-model="lastName"-->
          <!--            :rules="nameRules"-->
          <!--            :counter="10"-->
          <!--            label="שם משפחה"-->
          <!--            required-->
          <!--          ></v-text-field>-->
          <!--        </v-col>-->
        </v-row>
        <v-row justify="center">
          <!--        <v-col cols="4" md="4">-->
          <!--          <v-text-field-->
          <!--            v-model="email"-->
          <!--            :rules="emailRules"-->
          <!--            label="דואר אלקטרוני"-->
          <!--            required-->
          <!--          ></v-text-field>-->
          <!--        </v-col>-->
          <v-col cols="12" sm="6" lg="4">
            <v-text-field
              v-model="phoneNumber"
              :rules="phoneRules"
              label="מספר טלפון"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="valid"
              class="primary px-10"
              x-large
              @click="submit({ firstName, phoneNumber })"
              rounded
              :disabled="disabled"
            >
              שלח
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <v-btn class="primary" x-large :href="contactNumber" rounded>
              <!--            @click="-->
              <!--              $gtag.event('contactUs_phone_btn_click', {-->
              <!--                method: 'click',-->
              <!--                page: 'footer'-->
              <!--              })-->
              <!--            "-->
              <div class="pl-3">צרו קשר</div>
              <v-icon>
                mdi-phone
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </transition>
    <div id="contactUs"></div>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs",

  data: () => ({
    valid: false,
    firstName: "",
    lastName: "",
    disabled: false,
    isLoading: false,
    nameRules: [
      v => !!v || "שדה חובה",
      v => v.length <= 10 || "10 אותיות לכל היותר"
    ],
    email: "",
    emailRules: [
      v => !!v || "דואר אלקטורני הוא שדה חובה",
      v => /.+@.+/.test(v) || 'דוא"ל לא תקין'
    ],
    phoneNumber: "",
    phoneRules: [
      v => !!v || "מספר טלפון הוא שדה חובה",
      v => (Number(v) && v.length === 10) || "מספר לא תקין"
    ]
  }),
  methods: {
    submit(data) {
      this.disabled = true;
      this.isLoading = true;
      if (
        this.$route.path === "/campaign" &&
        this.$route.query.campaign === "zap"
      ) {
        // this.$gtag.event("contactUs_send_details_btn_click", {
        //   method: "campaign-click",
        //   page: "footer"
        // });
      } else {
        // this.$gtag.event("contactUs_valid_btn_click", {
        //   method: "campaign-click",
        //   page: "footer"
        // });
      }

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let dataToSend = [];

      dataToSend.push({
        id: `1`,
        name: data.firstName,
        value: data.phoneNumber
      });

      const raw = JSON.stringify(dataToSend);

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(
        "https://4zfhpadrl9.execute-api.us-east-1.amazonaws.com/default/sendEmail",
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          this.disabled = false;
          this.firstName = "";
          this.phoneNumber = "";
          this.response = result;
          this.isLoading = false;
        })
        .catch(error => console.log("error", error));
    },
    openWApp() {
      window.location.href = "https://wa.me/972792016611";
    }
  },
  computed: {
    isFieldsValid() {
      return true;
    },
    contactNumber() {
      if (
        this.$route.path === "/campaign" &&
        this.$route.query.campaign === "zap"
      ) {
        return "tel:+972723307127";
      } else {
        return "tel:+972792016611";
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
